@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.dashboard-main * {
  font-family: "DM Sans", sans-serif !important;
}
.active .MuiButtonBase-root {
  background-color: #ff57411a !important;
}

@media only screen and (max-width: 600px) {
  .dashboard-main {
    margin-top: 0px !important;
  }
  .open-drawer-btn {
    margin-left: 10px !important;
    margin-top: 5px !important;
  }
  .main-container {
    margin-top: 50px !important;
  }
}
.dashboard-main {
  display: block !important;
  background-color: var(--dashboard-bg);
  height: 100%;
  margin-top: -18px;
}
.font-gray {
  color: var(--gray-1-text) !important;
}

.dashboard-header {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--dark-color) !important;
}

.sub-title {
  color: var(--gray-1-text) !important;
  font-weight: 800;
}

.dashboard-header .sub-title {
  color: var(--gray-1-text) !important;
  font-weight: 800;
}
.search-bar {
  background-color: var(--light-color);
  border-radius: 30px;
  padding: 10px;
}
.dashboard-header .search-bar {
  display: flex;
  gap: 12px;
  align-items: center;
}
.search-bar * {
  color: var(--gray-1-text);
}
.total_states .totalstates {
  background-color: var(--light-color);
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  gap: 20px;
  height: 100%;
}
.state-icon {
  color: #ff5841;
  background-color: var(--gray-5-text);
  border-radius: 50%;
  padding: 15px;
}
.green-text {
  color: #05cd99;
}
.apexcharts-menu-icon {
  height: 40px !important;
  width: 40px !important;
}
.apexcharts-toolbar {
  gap: 8px !important;
}

.donation-graph-container {
  background-color: var(--light-color);
  border-radius: 20px;
  padding: 20px;
  display: flex;
}
.weekly-graph-container {
  background-color: var(--light-color);
  border-radius: 20px;
  padding: 20px;
}
.grey-bg {
  background-color: #28292c11;
}
.show-transaction-container {
  background-color: var(--light-color);
  border-radius: 20px;
  padding: 20px;
}

.show-traffic-container,
.show-piechart-container {
  background-color: var(--light-color);
  border-radius: 20px;
  padding: 20px;
  height: 100% !important;
}
.piechart-subdata {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: var(--light-color);
  border-radius: 20px;
  box-shadow: 0 10px 20px #17577217;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
}
.custom-datepicker .MuiOutlinedInput-input {
  height: 10px;
}
.custom-datepicker .css-z3c6am-MuiFormControl-root-MuiTextField-root {
  position: static;
}
.custom-datepicker:focus,
.custom-datepicker[aria-expanded="true"] {
  border: 1px solid orange;
  box-shadow: 0 0 0 1px orange;
}

.table-action .action-icons {
  background-color: #ff574154;
  padding: 5px;
  border-radius: 15%;
  color: #ff5741;
}
.css-10o2lyd-MuiStack-root {
  padding-top: 0 !important;
}
.custom-datepicker .MuiFormControl-root,
.custom-datepicker .MuiInputBase-root {
  border-radius: 8px !important;
}
.interval-selector .MuiStack-root {
  border-radius: 8px !important;
}
/* //configure design of current day in mui datepicker */
.css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border-color: #ff5741 !important;
  background-color: #ff574111 !important;
}
.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #ff5741 !important;
  color: white !important;
}
/* end */

.interval-selector .start,
.interval-selector .end {
  display: none;
  font-size: 14px;
  font-weight: 600;
}

.faq-question-section {
  width: 600px;
  box-shadow: 0px 0px 20px #cfdbfc77;
  border-radius: 10px;
  background-color: var(--light-color);
  position: relative;
  z-index: 5;
  left: 0;
  width: 40%;
  height: calc(100% - 50px);
  color: var(--dark-color);
}
.faq-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  gap: 15px;
  transition: all 0.3s;
  cursor: pointer;
  font-weight: 500;
}
.faq-question:hover {
  background-color: var(--hover-color);
  transition: all 0.3s;
}
.faq-question.active .active-dot {
  background-color: #ff5741;
}
.faq-question.active {
  color: var(--orange-color);
}

.faq-question .active-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ff574142;
}
.faq-ans-section {
  background-color: var(--light-color);
  top: 0;
  left: 35%;
  position: absolute;
  width: 500px;
  min-height: 100%;
  z-index: 0;
  border-radius: 20px;
  padding-left: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--dark-color);
}
.desktop-faq {
  /* background-color: #05cd99; */
  height: 100%;
  padding-block: 50px;
}

@media (min-width: 1552px) {
  .total_states {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .traffic-pie-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }
}

@media (min-width: 1326px) and (max-width: 1552px) {
  .total_states {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .traffic-pie-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }
}
@media (min-width: 1064px) and (max-width: 1326px) {
  .total_states {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .traffic-pie-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 15px;
  }
  .filter-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .filter-bar .date-selector-div {
    width: 100%;
    justify-content: space-between;
  }
  .filter-bar .add-div {
    width: 100%;
    justify-content: end;
  }
}

@media (max-width: 1064px) {
  .total_states {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .traffic-pie-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 15px;
  }
  .filter-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .filter-bar .date-selector-div {
    width: 100%;
    justify-content: space-between;
  }
  .filter-bar .add-div {
    width: 100%;
    justify-content: end;
  }
}

@media (max-width: 682px) {
  .total_states {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 723px) {
  .donation-graph-container {
    display: block;
  }
  .dashboard-header {
    display: block;
  }
  .search-bar {
    margin-top: 10px;
  }
  .interval-selector {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .interval-selector .to {
    display: none;
  }
  .interval-selector .start,
  .interval-selector .end {
    display: block;
  }
}

@media (max-width: 927px) {
  .filter-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: start;
  }
  .filter-bar .date-selector-div {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  .filter-bar .add-div {
    width: 100%;
    justify-content: end;
  }
}

@media (max-width: 379px) {
  .filter-bar .add-div {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: end;
    gap: 10px;
    flex-direction: column-reverse;
  }
}

@media (max-width: 1052px) {
  .faq-mobile {
    display: block;
  }
  .faq-desktop {
    display: none;
  }
}

@media (max-width: 640px) {
  .search-bar {
    display: none !important;
  }
}
.bg-white {
  background-color: var(--light-color) !important;
  color: var(--dark-color) !important;
}

.white-text {
  color: var(--dark-color) !important;
}
.apexcharts-xaxis-label tspan {
  color: var(--dark-color) !important;
  fill: var(--dark-color) !important;
}
.dashboard-layout {
  color: var(--dark-color) !important;
  background-color: var(--light-color);
}
.MuiDivider-root {
  border-color: var(--dark-color) !important;
}


#simple-search{
  background-color: var(--search-bg);
  color: var(--search-text);
}

label{
  color: var(--dark-color) !important;
}
html[data-theme="light"] {
  --light-color: #fff;
  --gray-5-text: #f6f6f6;
  --search-bg: #f5f5f5;
  --search-text: #b5b5b5;
  --gray-1-text: #28292c86;
  --dark-color: #000;
  --dashboard-bg: #f6f6f6;

  /* table cell light*/
  --table-cell-bg: #f9fafb;
  --table-cell-text-color: #000;
  --table-text-color: #2f2f2f;
  --table-rows-bg-color: #fff;

  /* faq section */
  --hover-color:#ff574109;
  --orange-color:#ff5551;
}

html[data-theme="dark"] {
  --light-color: rgb(44, 44, 44);
  --gray-5-text: #444444;
  --gray-1-text: rgb(204, 204, 204);
  --dark-color: #fff;
  --dashboard-bg: #4d4d4d;
  --search-bg: #444444;
  --search-text: #b5b5b5;

  /* table cell dark*/
  --table-cell-bg: #1c1c1c;
  --table-cell-text-color: #f5f5f5;
  --table-text-color: #2f2f2f;
  --table-rows-bg-color: #e0e0e0;

  /* faq section */
  --hover-color:#503b399f;
  --orange-color:#ffb4b3;
}

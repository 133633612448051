.menu-container {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-items: center;
    justify-content: space-evenly;
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.13);
    z-index: 100;
    transition: all .3s;
}

.main-section {
    transition: all .3s;
    margin-top: 100px;
}

.menu-item {
    padding: 20px;
    color: #52545E;
    font-size: 16px;
    font-weight: 500;
}

.logo {
    height: 35px;
}

.menu-item:hover,
.menu-item:active,
.active {
    color: #FF5841;
}


.sidebar {
    display: none;
    width: 240px;
    height: 100%;
    background-color: #ffffff;
    color: #fff;
    position: fixed;
    top: 0;
    left: -250px;
    transition: all 0.3s ease-in-out;
    border-radius: 0px 35px 35px 0px;
    box-shadow: none;
}

.sidebar.open {
    left: 0;
}

.toggle-btn {
    display: none;
}

.toggle-btn:hover {
    box-shadow: none;
}

.desc-text-hero {
    font-size: 18px;
}

@media (max-width: 921px) {
    
    /* .background-blur-effect .index-page{
        height: 100%;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    } */
    .desc-text-hero {
        font-size: 16px;
    }
    
    .background-blur-effect {
        filter: brightness(40%) blur(18px);
        transition: all .3s;
    }

    .sidebar .menu-item {
        display: block;
        margin-block: -10px;
    }

    .sidebar {
        z-index: 5000;
        display: block;
    }

    .toggle-btn {
        display: block;
    }

    .menu {
        display: none;
    }

    .login-btn {
        display: none;
    }

    .menu-container {
        justify-content: space-between;
        padding-inline: 20px;
    }

    .sidenav-close-btn {
        display: none;
    }

    .sidenav-close-btn-open {
        display: absolute;
        height: 22px;
        margin-left: 80px;
    }
}


@media (max-width: 970px) and (min-width: 922px) {
    .menu-item {
        padding: 10px;
    }
}